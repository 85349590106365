import {
  ImageList,
  ImageListItem,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Alert,
} from '@mui/material';
import img from './assets/stargazing.jpg';
import img2 from './assets/vkro.jpg';
import { useState } from 'react';

const itemData = [
  {
    id: 1,
    name: 'Ples pod kišom meteora',
    img,
    active: true,
  },
  {
    id: 2,
    name: 'Projekat u izradi',
    img: img2,
    active: false,
  },
  {
    id: 3,
    name: 'Projekat u izradi',
    img: img2,
    active: false,
  },
];

function App() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isApiResponseSuccess, setIsApiResponseSuccess] = useState(null);

  function validateEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  }

  const onSubmit = async () => {
    const body = JSON.stringify({
      email,
      access_key: 'fa3dd31d-4dde-4cd6-9a58-b9f592536ecd',
    });

    const res = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body,
    }).then((res) => res.json());

    handleApiResponse(res.success);
  };

  function handleApiResponse(success) {
    setIsApiResponseSuccess(success);
    setTimeout(() => {
      setIsApiResponseSuccess(null);
    }, 800);
    setTimeout(() => {
      setOpen(false);
    }, 1200);
  }

  return (
    <div className="App">
      <ImageList className="imageList" cols={1}>
        {itemData.map((item) => (
          <ImageListItem key={item.id}>
            <img
              style={{ filter: !item.active && 'brightness(19%)' }}
              src={item.img}
            />
            {item.active && (
              <Button onClick={() => setOpen(true)} variant="contained">
                Pišite priču sa nama
              </Button>
            )}
            <h3
              style={{
                bottom: item.active ? '60%' : '50%',
                marginLeft: item.active && '5px',
              }}
            >
              {item.name}
            </h3>
          </ImageListItem>
        ))}
      </ImageList>

      {/* DIALOG */}
      <Dialog open={open}>
        {isApiResponseSuccess !== null && (
          <Alert
            style={{
              position: 'absolute',
              top: '5px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            severity={isApiResponseSuccess ? 'success' : 'error'}
          >
            {isApiResponseSuccess ? 'Success' : 'Error'}
          </Alert>
        )}
        <DialogContent>
          <DialogContentText style={{ marginTop: '5px' }}>
            U saradnji sa astronomskim društvom "Orion" organizujemo promatranje{' '}
            <span>perzeida</span> u planinskom okruženju, uz bogat sadržaj{' '}
            <span>elektronske muzike</span> koju priređuju lokalni dj-evi od
            zalaska do izlaska sunca.
          </DialogContentText>
          <TextField
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail();
            }}
            autoComplete="off"
            required={true}
            error={!isValidEmail}
            autoFocus
            margin="dense"
            id="name"
            label="Unesite email za više informacija..."
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={!isValidEmail} onClick={onSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
