import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import logo from './assets/bihtechnopokret-original.png';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div>
      <h1>BIH TECHNO POKRET</h1>
      <h2>tražimo sponzore</h2>
    </div>
    <App />
    <img
      style={{
        // position: 'absolute',
        // bottom: '3%',
        width: '140px',
        // left: '50%',
        // transform: 'translate(-50%)',
      }}
      src={logo}
    />
  </React.StrictMode>
);
